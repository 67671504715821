/* html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
} */

#app-root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
  background: #272727;
}


body {
  /* cursor: url('/images/ellipse.svg'), pointer; */
  /* cursor: -webkit-image-set(url('/images/ellipse.svg') 1x, url('/images/ellipse.svg') 2x), pointer; */
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, Roboto, Ubuntu, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 3px;
  background: #272727;
}

.loading-bar {
  height: 3px;
  background: white;
}

.loading-data {
  display: inline-block;
  position: relative;
  font-variant-numeric: tabular-nums;
  margin-top: 0.8em;
  color: #f0f0f0;
  font-size: 0.6em;
}
